import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import "../../styles/EventResultForAdmin.css";
import { useSelector } from "react-redux";
import { BackArrowSVG, SortingIconSVG } from "../../utils";

function UsersForAdmin() {
  const { id } = useParams();
  const [quizStatsBasedOnUsers, setquizStatsBasedOnUsers] = useState([]);
  const token = useSelector((state) => state.user?.token);

  useEffect(() => {
    const fetchUsersData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASEURL}/user/getAllUsers`
        );
        const data = response.data.response?.result;
        setquizStatsBasedOnUsers(data);
      } catch (error) {
        console.error("Error fetching questions data:", error);
      }
    };
    // Call the functions to fetch data
    fetchUsersData();
  }, [id, token]);

  //sorting algo for user based table
  const [sortedColumnUser, setSortedColumnUser] = useState(
    "id"
  );
  const [sortDirectionUser, setSortDirectionUser] = useState("asc");

  const handleSortUser = (column) => {
    console.log("Sort by %@", column);
    if (sortedColumnUser === column) {
      // If the same column iUsers clicked again, reverse the sorting direction
      setSortDirectionUser(sortDirectionUser === "asc" ? "desc" : "asc");
    } else {
      // If a new column is clicked, set it as the sorted column and set the sorting direction to ascending
      setSortedColumnUser(column);
      setSortDirectionUser("asc");
    }
  };

  const sortedDataUser = [...quizStatsBasedOnUsers].sort((a, b) => {
    if (sortedColumnUser === "state") {
      return sortDirectionUser === "asc"
        ? a.state.localeCompare(b.state)
        : b.state.localeCompare(a.state);
    } else if (sortedColumnUser === "userName") {
      return sortDirectionUser === "asc"
        ? a.userName.localeCompare(b.userName)
        : b.userName.localeCompare(a.userName);
    } else if (sortedColumnUser === "city") {
      return sortDirectionUser === "asc"
        ? a.city.localeCompare(b.city)
        : b.city.localeCompare(a.city);
    } else if (sortedColumnUser === "id") {
        return sortDirectionUser === "asc"
          ? a.id - b.id
          : b.id - a.id
      } else {
      // For other columns, maintain the current order
      return 0;
    }
  });
  

  return (
    <div className="single-event-container margin-top">
      <h2 className="">Users1</h2>
      
      <div className="table-container">
              <table className="responsive-table">
                <thead>
                  <tr>
                  <th onClick={() => handleSortUser("id")}>
                      <div className="sortingParent">
                        User ID
                        <span>
                          <SortingIconSVG />
                        </span>
                      </div>
                    </th>
                    <th onClick={() => handleSortUser("userName")}>
                      <div className="sortingParent">
                        User Name
                        <span>
                          <SortingIconSVG />
                        </span>
                      </div>
                    </th>
                    <th>Mobile</th>
                    <th>Email</th>
                    <th onClick={() => handleSortUser("city")}>
                      <div className="sortingParent">
                        City
                        <span>
                          <SortingIconSVG />
                        </span>
                      </div>
                    </th>
                    <th onClick={() => handleSortUser("state")}>
                      <div className="sortingParent">
                        State
                        <span>
                          <SortingIconSVG />
                        </span>
                      </div>
                    </th>
                    <th>Country</th>
                  </tr>
                </thead>
                <tbody>
                  {sortedDataUser.map((item, key) => (
                    <tr key={item.id}>
                      <td>{item.id}</td>
                      <td>{item.userName}</td>
                      <td>{item.mobile}</td>
                      <td>{item.email}</td>
                      <td>{item.city}</td>
                      <td>{item.state}</td>
                      <td>{item.country}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
    </div>
  );
}

export default UsersForAdmin;
