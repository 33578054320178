import { createBrowserRouter } from "react-router-dom";
import User from "./pages/user/User";
import Admin from "./pages/admin/Admin";
import Layout from "./components/Layout";
import Events from "./pages/admin/Events";
import SingleEvent from "./pages/admin/SingleEvent";
import AdminProfile from "./pages/admin/AdminProfile";
import UserLayout from "./components/user/UserLayout";
import UserProfile from "./pages/user/UserProfile";
import PollResults from "./pages/PollResults";
import Announcements from "./pages/admin/Announcements";
import EventResultForAdmin from "./pages/admin/EventResultForAdmin";
import UsersForAdmin from "./pages/admin/Users";
const router = createBrowserRouter([
  {
    path: "/home",
    element: (
      <UserLayout>
        <User />
      </UserLayout>
    ),
  },
  {
    path: "/",
    element: (
      <Layout>
        <Admin />
      </Layout>
    ),
  },
  {
    path: "/admin/profile",
    element: (
      <Layout>
        <AdminProfile />
      </Layout>
    ),
  },
  {
    path: "/user/profile",
    element: (
      <UserLayout>
        <UserProfile />
      </UserLayout>
    ),
  },
  {
    path: "/events",
    element: (
      <Layout>
        <Events />
      </Layout>
    ),
  },
  {
    path: "/announcements",
    element: (
      <Layout>
        <Announcements />
      </Layout>
    ),
  },
  {
    path: "/users",
    element: (
      <Layout>
        <UsersForAdmin />
      </Layout>
    ),
  },
  {
    path: "/events/:id",
    element: (
      <Layout>
        <SingleEvent />
      </Layout>
    ),
  },
  {
    path: "/events/presenter",
    element: <PollResults />,
  },
  {
    path: "/event/result/:id",
    element: (
      <Layout>
        <EventResultForAdmin />
      </Layout>
    ),
  },
]);
export default router;

